import React from "react";
import { graphql, Link } from "gatsby";

import { TagButton, ArticleCard } from "../components/styled";
import { Layout } from "../components";

interface INode {
  node: {
    excerpt: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      date: string;
      description: string;
      title: string;
    };
  };
}

interface IAllMarkdownRemark {
  edges: INode[];
  totalCount: number;
}

interface IProps {
  pageContext: {
    tag: string;
  };
  data: {
    allMarkdownRemark: IAllMarkdownRemark;
  };
}

const BlogTag: React.FC<IProps> = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`;

  return (
    <Layout>
      <div>
        <h1>{tagHeader}</h1>
        {edges.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <ArticleCard key={node.fields.slug}>
              <small>{node.frontmatter.date}</small>
              <h3>
                <Link to={node.fields.slug}>{title}</Link>
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </ArticleCard>
          );
        })}
        <div>
          <TagButton to="/tags">All tags</TagButton>
        </div>
      </div>
    </Layout>
  );
};

export default BlogTag;

export const query = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
